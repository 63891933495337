.tabWrapper{
  background-color: #f3f3f4;
  padding: 1rem !important;
}
.activeBtn{
  background-color: #002664 !important;
  color: #fff !important;
  border-top-left-radius: 0.2rem !important;
  border-bottom-left-radius: 0.2rem !important;
  /* border-radius: 0.2rem !important; */
}
.deActiveBtn{
  background-color: #fff !important;
}
.tabBtn{
  margin-bottom: 0px !important;
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}
.tabBtn:focus{
  box-shadow: none !important;
}
.heading{
  margin: 1rem 0rem;
  font-weight: 600;
  font-size: 1.5rem;
}
.flex{
  display: flex;
  align-items: center;

}

.iconWrapper{
  width: 32px;
  height: 24px;
  cursor: pointer !important;
  margin-left: 1rem;
  margin-bottom: 1rem;
}
.iconWrapper > img {
  /* width: 100%;
  height: 100%; */
  width: 100%;
}