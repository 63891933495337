.grid{
  display: grid;
  grid-template-columns: 120px 120px 120px 250px 120px;
  /* grid-template-columns: repeat(5, 1fr); */
  max-width: 900px;
  margin-bottom: 1rem;
  grid-gap: 1rem;
}
.label{
}
.dropdown{
  min-width: 250px !important;
  background-color: red;

}
.label > .btn{
  min-width: 200px !important;

}
.dateRangeWrapper{
  min-width: 200px;
}