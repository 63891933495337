.wrapper{
  display: flex;
align-items: center;
justify-content: center;
height: 100%;
}
.definationWrapper{
  min-width: 500px !important;
  min-height: 300px;
  padding: 2rem;
  border-radius: 0.5rem;
  margin-top: auto;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.row{
  width: 100%;
  background-color: #fff;
  margin-bottom: 0.8rem;
}
.header{
  background: #b1b1b136;
  padding: 0.5rem;
  border-radius: 0.2rem;
  margin-bottom: 0.5rem;
  font-weight: 600;
  text-transform: capitalize;
}
.subWrapper{
  /* overflow: auto; */
  background: #fff;
  display: flex;
  height: 450px;
  flex-direction: column;
  padding: 1rem;
  max-width: 800px;
  border-radius: 0.5rem;
}
.label{
  font-weight: 600;
}
.contentWrapper{
  overflow: auto;
  background: #fff;
  display: flex;
  height: 400px;
  flex-direction: column;
  padding: 1rem;

}
.cancelBtn{
  background-color: #dc354547 !important;
  color: #000 !important;
}
.noData{
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 800px;
  height: 100%;
}
.footer{
  padding: 0.5rem 0rem;
}