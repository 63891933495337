.tabWrapper{
  background-color: #f3f3f4;
  padding: 1rem !important;
}
.activeBtn{
  background-color: #bf870e !important;
  color: #fff !important;
  border-top-left-radius: 0.2rem !important;
  border-bottom-left-radius: 0.2rem !important;
  /* border-radius: 0.2rem !important; */
}
.deActiveBtn{
  background-color: #fff !important;
}
.tabBtn{
  margin-bottom: 0px !important;
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
  text-transform: uppercase;
}
.tabBtn:focus{
  box-shadow: none !important;
}
.heading{
  margin: 1rem 0rem;
  font-weight: 600;
  font-size: 1.5rem;
}
.comingSoonWrp{
  width: 100%;
  min-height: 250px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
  font-weight: 600;
  background-color: #f3f3f4;
}
.iconBtn{
  padding: 2px 6px;
  border-radius: 0;
  border: 2px solid #fff !important;

  background: #fff;
  margin-right: 0.5rem !important;
  display: flex;
  align-items: center;
  justify-content: center;
  border-left: 2px solid #8080803d;
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}
.activeIconBtn{
  /* background-color: #bf870e !important; */
  border: 2px solid #bf870e !important;
  border-top-right-radius: 0.2rem !important;
  border-bottom-right-radius: 0.2rem !important;
}
.iconWrapper{
  width: 16px;
  height: 24px;
  cursor: pointer !important;
}
.iconWrapper > img {
  /* width: 100%;
  height: 100%; */
  width: 100%;
}
.firstHead{
  width: 390px !important;
}
.noteWrp{
  margin: 1rem 0rem;
  background-color: #0080001c;
  padding: 0.5rem;
  border-radius: 0.2rem;
}
.greenBX{
  width: 10px !important;
    height: 10px !important;
    background-color: green;
    border-radius: 2px;
    display: inline-block;
}
.redBX{
  width: 10px !important;
    height: 10px !important;
    background-color: red;
    border-radius: 2px;
    display: inline-block;
}