th{
  background: #e8f0fc !important;
  border-bottom: none !important;
  border-right: 1px solid #dee2e6 !important;
  font-size: 16px;
}
/* th:nth-last-child(1){
  border-right: none !important;
} */
tr > td{
  border-right: 1px solid #dee2e6 !important;
  /* background: red !important; */
  font-size: 14px !important;
}
.heading{
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 390px !important;
}
.iconWrapper{
  width: 30px;
  height: 30px;
  cursor: pointer;
}
.iconWrapper > img {
  /* width: 100%;
  height: 100%; */
  width: 100%;
}
.firstHead{
  width: 390px !important;
}
.nagetive{
  color: red;
}
.positive{
  color: green;
}
.orange{
  color: orange;
}
.head{
  z-index: 10 !important;
}
.blurContainer{
  position: relative;
  cursor: pointer;
}
thead > tr > th:first-child{
  min-width: 120px !important;
}
.titleWrp{
  width: 100%;
    max-width: 48% !important;
}
.blur{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(10px);
  background: #2020200d;
}
.lockBTN{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); /* Rotate text upside down */
  color: #000;
}
.lokcbutton-block {
  height: 100%;
  width: 100%;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  backdrop-filter: blur(2px);
  z-index: 10;
}

.lokcbutton-content {
  height: 100%;
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.lock-button {
  background-color: #002664 !important;
  color: #FFF !important;
  border-radius: 10px !important;
}

.lock-button:hover {
  background-color: #003fa5 !important;
  color: #FFF !important;
  border-radius: 10px !important;
}

.elite-subscription {
  /* background: #FAF5F1; */
  color: black;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.elite-subscription h2{
  font-size: 30px;
  line-height: 30px;
  margin-bottom: 30px;
  margin-top: 10px;
}

.elite-subscription p {
  color: black;
  font-size: 16px;
  line-height: 22px;
  margin-bottom: 20px;
}

.elite-button-group {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  margin: 20px 0;
}

.elite-button-monthly {
  background-color: #4ea4f4;
  background-image: linear-gradient(0deg,rgba(0,0,0,.06) 0,hsla(0,0%,100%,.06));
  border: none;
  box-shadow: 0 1px 5px rgba(0,0,0,.2);
  color: #fff;
  display: inline-block;
  font-size: 16px;
  height: 84px;
  line-height: 22px;
  outline: 0;
  padding: 19px 0;
  text-shadow: 0 1px 3px rgba(0,0,0,.2);
  width: 220px;
}

.elite-button-yearly {
  background-color: #9f82f3;
  background-image: linear-gradient(0deg,rgba(0,0,0,.06) 0,hsla(0,0%,100%,.06));
  border: none;
  box-shadow: 0 1px 5px rgba(0,0,0,.2);
  color: #fff;
  display: inline-block;
  font-size: 16px;
  height: 84px;
  line-height: 22px;
  margin-left: 8px;
  outline: 0;
  padding: 19px 0;
  position: relative;
  text-shadow: 0 1px 3px rgba(0,0,0,.2);
  width: 220px;
}
.elite-button_tooltip {
  background: #ffe13e;
  border-radius: 0;
  color: #474b59;
  font-size: 11px;
  height: 26px;
  line-height: 26px;
  padding-left: 10px;
  padding-right: 10px;
  position: absolute;
  right: -45px;
  top: -39px;
}

.elite-button_tooltip:after {
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 8px solid #ffe13e;
  content: "";
  height: 0;
  left: 16px;
  margin-left: -8px;
  position: absolute;
  top: 100%;
  width: 0;
}
.cancelBtn{
  background-color: #dc354547 !important;
  color: #000 !important;
  max-width: 100px;
    margin-left: auto;
}