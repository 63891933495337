.grid{
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 1rem;
}
.leftMenu{
  width: 200px !important;
  min-width: 200px !important;
  height: 150px;
  overflow: hidden;
  margin-right: 1rem;
}
.leftMenu > img{
  width: 100%;
  height: 100%;
  border-radius: 0.5rem;
}
.flex{
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #20202033;
  padding: 0.5rem;
  border-radius: 0.5rem;
  cursor: pointer !important;
}
.title{
  font-size: 0.9rem;
  margin-bottom: 0.5rem;
}
.author{
  font-size: 0.9rem;
  margin: 0.5rem 0rem;
}
.name{
  margin-left: 0.5rem;
  font-weight: 600;
  opacity: 0.8;
}
@media screen and (max-width: 1400px)  {
  .grid{

    grid-template-columns: repeat(2, 1fr);
  }
  
}
.iconWrapper{
  width: 36px;
  height: 36px;
  cursor: pointer !important;
  margin-left: 1rem;
  border: 1px solid #20202075;
  border-radius: 0.2rem;
  padding: 0.2rem;
}
.iconWrapper > img {
  width: 100%;
  height: 100%;
}
.flexCenter{
  display: flex;
  align-items: center;
}
.grid{
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 1rem;
}
@media screen and (max-width: 1499px) {
  .grid{
    grid-template-columns: repeat(3, 1fr);
  }
}
@media screen and (max-width: 991px) {
  .grid{
    grid-template-columns: repeat(2, 1fr);
  }
}