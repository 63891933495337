.wrapper{
  width: 100%;
  padding: 2rem 5rem;
  display: grid;
  grid-template-columns: 400px 400px;
  grid-gap: 5rem;
  color: #000;
  margin: 0 auto;
  max-width: 1200px;
  
}


.heading{
  color: #1a1a1a99;
}
.remingDays{
  font-size: 1.8rem;
  font-weight: 600;
}
.smText{
  font-size: 0.9rem;
}
.row{
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0.5rem 0rem;
}
.saprator{
  height: 3px;
  width: 100%;
  background-color: #e0e0e0;
}
.xsmText{
  opacity: 0.8;
  font-size: 0.8rem;
}
.rightMenu{
  
}
.rightMenu > .emailWrapper{
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #e0e0e0;
  padding: 0.5rem;
  opacity: 0.7;
  margin-bottom: 0.5rem;
  border-radius: 0.2rem;
}
.paymentCard{
  width: 100%;
  margin-bottom: 0.5rem;
}
.cardExpiryWrp{
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  margin-bottom: 0.5rem;
}
.input{
  padding: 0.4rem 0.5rem;
  border-radius: 0.2rem;
  border: 1px solid #e0e0e0;
  outline: none;
  width: 100%;
}

.cvc{
  margin-left: 0.5rem;
  width: auto;
}
.btnWrapper{
  margin: 1rem 0rem;
  width: 100%;
}
.freeTrailBtn{
  background-color: #002664;
  color: #fff;
  border: 1px solid #002664;
  outline: none;
  width: 100%;
  padding: 0.4rem 0;
  border-radius: 0.2rem !important;
}
.inputWrapper{
  margin-bottom: 0.5rem;
}
.inputWrapper > label{ 
  font-size: 13px;
  font-weight: 600;
  color: #1a1a1ab3;
}
.head{
  font-size: 13px;
  font-weight: 600;
  color: #1a1a1ab3;
  
}
.footerSmText{
  font-size: 0.8rem;
  color: #1a1a1ab3;
}
.footerSection{
  width: 100%;
  padding: 2rem 5rem;
  display: flex;
  align-items: center; 
  margin: 0 auto;
  max-width: 1200px;
}
.poweredBy{
  font-size: 0.9rem;
  margin-right: 1rem;
}
.poweredBy > strong{
  opacity: 0.7;
}
.smTextFoot{
  font-size: 0.9rem;
  margin-right: 0.5rem;
  cursor: pointer;

}
.cardImgs{
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  right: 12px;
  top: 6px;
  text-align: center;
}
.cardImgs > img{
  margin-right: 0.3rem;
}
.cvvImgWrp{
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  right: 3px;
  text-align: center; 
  width: 40px;
}
.cvvImgWrp > img{
  width: 100%;
}