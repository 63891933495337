.wrapper{
  display: flex;
align-items: center;
justify-content: center;
height: 100%;
}
.definationWrapper{
  min-width: 500px !important;
  min-height: 300px;
  padding: 2rem;
  border-radius: 0.5rem;
  margin-top: auto;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  
}
.row{
  width: 60%;
  margin-left: auto;
  box-sizing: border-box;
  margin-right: auto;
  display: block;
  backdrop-filter: blur(14.5px);
  background-color: rgb(251, 252, 254);
  border: 2px solid #002664;
  border-radius: 0.5rem;
  box-shadow: rgba(31, 38, 135, 0.37) 0px 8px 32px 0px;
  max-width: 95%;
  outline: none;
  position: relative;
  background-clip: padding-box, border-box;
  /* background-image: linear-gradient(white, white), linear-gradient(to right bottom, rgb(253, 24, 146) 0%, rgb(254, 200, 55) 25%, rgb(184, 253, 51) 50%, rgb(44, 144, 252) 75%, rgb(184, 39, 252) 100%); */
  background-origin: border-box;
  padding: 2rem;
}
.header{
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0.5rem;
}
.header > .heading{
  font-size: 1.5rem !important;
  font-weight: 700;
}
.saprator{
  /* background: linear-gradient(to right, rgb(253, 24, 146) 0%, rgb(254, 200, 55) 25%, rgb(184, 253, 51) 50%, rgb(44, 144, 252) 75%, rgb(184, 39, 252) 100%); */
  background-color: #002664;
    height: 2px;
    padding-left: 24px;
    padding-right: 24px;
    width: 100%;
}
.bodyContent{
  width: 100%;
  margin: 1rem 0rem;
}
.head{
  text-decoration: underline;
  font-weight: 600;
  font-size: 1.3rem; 
  margin-bottom: 0.5rem;
}
.exprience{
  margin-bottom: 1rem;
  
}
.noteHead{
  font-weight: 600;
  font-size: 1.2rem;
  margin-top: 0.5rem;
}
.purchaseBtn{
  background-color: #4987eb;
  color: #fff ;
  padding: 0.5rem;
  margin: 1rem auto;
  border-radius: 0.5rem;
  cursor: pointer;
  border: 1px solid #4987eb !important;
}
.purchaseBtn:hover{
  color: #fff !important;
}

.planTitle{
  margin-top: 1rem;
  margin-bottom: 0rem;
  font-size: 0.8rem;

}
.btnWrp{
  display: flex;
  align-items: center;
  background-color: #e8f0fc;
  margin: 0 auto;
  max-width: 300px;
  min-height: 30px;
  border-radius: 96px;
}
.monthlyBtn, .yearlyBtn{
  background-color: #e8f0fc;
  border-radius: 96px;
  cursor: pointer;
  padding: 12px 32px;
  width: 50%;
  text-align: center;
  cursor: pointer;
  transition: 0.3s ease-in-out;
}
.active{
  transition: 0.3s ease-in-out;
  background-color: rgb(73, 135, 235);
  border-radius: 96px;
  color: white;
  cursor: pointer;
  padding: 12px 32px;
  width: 50%;
}
.actionWrapper{
  text-align: center;
}
.priceWrp{
  display: flex;
  align-items: flex-start;
  justify-content: center;
  margin-top: 2rem;
}
.currency{
  font-size: 1.2rem;
  opacity: 0.8;
  margin-top: 0.5rem;
  margin-right: 0.5rem;
  font-weight: 600;
}
.price{
  font-size: 2.8rem;
  font-weight: 700;

}
.month{
  margin-top: 1.2rem;
  font-size: 0.9rem;
  margin-left: 0.4rem;
}