.definationWrapper{
  min-width: 500px !important;
  min-height: 300px;
  padding: 2rem;
  border-radius: 0.5rem;
  margin-top: auto;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  
}
.listWrapper{
  margin-top: 2rem;
}
.wrapper{
  width: 50%;
  margin-left: auto;
  box-sizing: border-box;
  margin-right: auto;
  display: block;
  backdrop-filter: blur(14.5px);
  background-color: rgb(251, 252, 254);
  border: 2px solid #002664;
  border-radius: 0.5rem;
  box-shadow: rgba(31, 38, 135, 0.37) 0px 8px 32px 0px;
  max-width: 95%;
  outline: none;
  position: relative;
  background-clip: padding-box, border-box;
  background-origin: border-box;
  padding: 2rem;
}
.mainMenu{
  display: grid;
  grid-template-columns: 80px 1fr;
  grid-gap: 1rem;
  place-items: center;
}
.leftMenu{
  width: 100%;
  height: 100%;
}
.leftMenu > img{
  width: 100%;
  height: 100%;
}
.highlightText{
  color: #00DE4C;
  font-weight: 600;
}
.checkIcon{
  width: 25px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  /* padding: 0.2rem; */
  border-radius: 50%; 
  border: 1px solid #00DE4C;
  margin-right: 0.5rem;
}
.row{
  margin-bottom: 0.5rem;
  display: flex;
  align-items: center;
}
.craditDesign{
  background: #86E77926;
  border-radius: 0.5rem;
  color: #FAB200;
  padding: 0.5rem;
  margin: 1rem 0rem;
}
.freeTrail{
  text-align: center;
  width: 60%;
  margin-left: auto;
  margin-right: auto;
}
@media screen and (max-width: 1400px) {
 .wrapper{
   width: 80%;
  }
}
.btnWrp{
  text-align: center;
  width: 60%;
  margin-left: auto;
  margin-right: auto;
}
.btn{
  border: none;
  outline: none;
  background-color: #00DE4C;
  padding: 0.5rem 3rem;
  text-align: center;
  border-radius: 2rem;
  color: #fff;
  cursor: pointer;
  margin-bottom: 1rem;
}
.account{
  text-align: center;
  margin-bottom: 1rem;
}
.login{
  border-radius: 3rem;
  background-color: transparent;
  padding: 0.4rem 1rem;
  border: 1px solid #4987eb;
  color: #4987eb;
  height: 40px;
  margin-left: 0.5rem;
  cursor: pointer;
}
.footerText{
  text-align: center;
}
.head{
  color: #002664;
  font-size: 2rem;
  font-weight: 700;
  font-family: Merriweather;
  text-align: center;
}
.footerText{
  color: #002664;
  font-size: 1.2rem;
  font-weight: 700;
  font-family: Merriweather;

}
.closeBtnWrp{
  margin-left: auto;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #222;
  height: 34px;
  border-radius: 0.2rem;
}