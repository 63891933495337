body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.top_button_panel .btn.active{
  background-color: #3751FF;
  border-color: #3751FF;
  color: #fff;
}
.accordion-button.active span{
  color: #3751FF;
}

.companyviewblk.v2.compny_left_detail .chart .card-text.up{
  border-left: 4px solid #13A41B;
}

.companyviewblk.v2.compny_left_detail .chart .card-text.down{
  border-left: 4px solid #DF0822;
}

.description-para.read-more-container{
  overflow-y: auto;
}

.up.scenario{
  border-left: 5px solid #13A41B;
}

.down.scenario{
  border-left: 5px solid #DF0822;
}

.up.scenario .up-down-color{
  color:#13A41B;
}

.down.scenario .up-down-color{
  color:#DF0822;
}

.subText {
  color: black !important;
  font-size: 15px !important;
  left: 190px !important;
  font-weight: 100 !important;
}

.up.scenario .up-down-bg-color{
  /*background-color:rgb(19 164 100 / 15%)*/
  color:#13A41B !important;
}

.down.scenario .up-down-bg-color{
  /* background-color:rgb(254 19 12 / 96%); 
  background-color:rgb(223 8 34 / 90%);*/
  color: rgb(223 8 34 / 90%);
}

.up-color{
  color:#13A41B !important;
}

.down-color{
  color:#DF0822 !important;
}


.up-color-rv{
  color:#13A41B !important;
  font-size: 10px;
  font-weight: normal;
}

.down-color-rv{
  color:#DF0822 !important;
  font-size: 10px;
  font-weight: normal;
}

.up-bg-color{
  background-color:rgb(19 164 100 / 15%)
}

.down-bg-color{
  /* background-color:rgb(254 19 12 / 96%); */
  background-color:rgb(223 8 34 / 90%);
  color: white;
}

.most_tables thead.bold-heading tr th{
  font-weight: bold !important;
  font-size: 12px !important;
  font-family: Poppins !important;
}

.laptopNav.light-nav .upperNavbar .input-group input.search-input, .laptopNav.light-nav .upperNavbar .input-group input.search-input::placeholder{
  color: black;
}

.font-weight-700 {
  font-weight: 700 !important;
}

.title-lt.fixed-margin + span{
  margin-bottom: 5px;
}

.company_detail_fix.company_details.symfinstatcs {
  padding: 20px 0 0;
  padding-bottom: 30px;
}

.guage-chart-container{
  background: #F8F8F8;
  border: 1px solid #EFEFEF;
  border-radius: 12px;
}

.most_tables.font_weight_normal>:not(caption)>*>*:nth-child(1) {
  text-align: left;
  font-weight: normal;
}


.most_tables.first_fixed_width>:not(caption)>*>*:nth-child(1) {
  min-width: 240px;
  max-width: 240px;
  width: 240px;
}

.most_tables.table>:not(:last-child)>:last-child>*{
  border-bottom: none !important;
}

.fixed-height-scroll-news{
  height: 550px;
  overflow-y: scroll;
}

.trading-ideas-left-side .leftsidefilter{
  width: 100%;
  border-top: 1px solid #dee2e6;
  border-bottom: 1px solid #dee2e6;
  height: 280px;
}

.trading-ideas-left-side .new_scenr_btn {
  border-left: 1px solid #dee2e6;
}

.text-grey {
  color: #808080;
}

.filterscreener_section.index {
    padding: 30px 0 50px;
}

.leftsidefilter.index {
  max-height: 85vh;
}

.righttablecontent.index {
  max-height: 85vh;
}

.cursor-pointer {
  cursor: pointer;
}

.hover-blue:hover {
  color:#3751FF;
}

.sector_sec_margin_top.sectors_sec {
  margin-top: 30px;
}

.blue-color {
  color: #3751FF;
}

.owl-carousel.testimonial-carousel .owl-item img {
    display: block;
    width: auto;
    
}

.justify-center {
  justify-content: center;
}

.accordion.accordion_bold .accordion-button span {
    font-size: 14px;
    font-weight: 600;
    color: #212121;
}

.pie-chart-tooltip{
  padding: 5px 10px;
  background-color: white;
  border: .5px solid black;
}

.market-news-container{
    padding: 15px 12px;
    background-color: #F8F8F8;
    border-radius: 4px;
}

.market-news-container p{
    font-weight: 600;
    margin: 0;
}

.news_content.news_title_container h5{
  margin-bottom: 0;
}

.news_content.news_title_container .news_symbol{
  margin-right: 10px;
}

.top_button_panel.v2.auto {
  width: auto
}

.leftsidefilter.custom-height {
  max-height: 900px;
}

/* .accordion.accordion_bold .accordion-button.active {
    background: hsl(232deg 100% 61% / 5%);
}

.accordion.accordion_bold .accordion-button.active span{
    color: #3751FF;
    font-weight: 600;
} */

/* me-3 for margin right */

.marquee-sticky.marquee-header,
.marquee2-sticky.marquee2 {
    /* position: sticky; */
    width: 100vw;
    max-width: 100%;
    height: 40px;
    overflow-x: hidden;
    background: #F8F8F8;
    
    display: flex;
    align-items: center;
    top: 110px;
    z-index: 1000;
}

.marquee2-sticky.marquee2 {
  top: 150px;
}
.marquee-sticky {
  background: #002664 !important;
}
.background-st-header{
  background: #002664 !important;

}
@media only screen and (max-width: 767px) {
  .marquee-sticky.marquee-header,
    .marquee2-sticky.marquee2 {
      top: 0;
      position: relative;
  }
}
.sticky-table-container thead tr th {
  border-left: 1px;
}

.sticky-table-container {
  border-color: #c5c5c5 !important;
  max-height: 60vh;
}

.sticky-first-column-container tbody tr td:nth-child(1) {
  position: sticky;
  left: 0;
  background-color: white;
}

.sticky-first-column-container thead tr th:nth-child(1) {
  position: sticky;
  z-index: 1;
  left: 0;
  top: 0;
}

.sticky-first-column-container {
  border: 1px solid #c5c5c5;
}
.custom-tooltip {
  background-color: white;
  padding: 10px;
  border: 1px solid #dfdfdf;
}
.custom-tooltip p {
  font-size: 12px;
}

.blurData {
  filter: blur(10px) !important;
}

.MuiMenu-list{
  display: flex !important;
  flex-direction: column !important;
  align-items: baseline !important;
  padding: 0.5rem !important;
}
.MuiMenuItem-root {
  padding: 0.2rem 0rem !important;
  width: 100%;
  justify-content: flex-start !important;
}
.ValuvationEllips{
    display: -webkit-box;
    /* max-width: 200px; */
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
}
.menuplationWrp{
  padding: 0.4rem 1rem;
    border: none;
    background-color: #002664 !important;
    color: #ffbf32;
    border-radius: 0.2rem;
    cursor: pointer;
}
.disabledBtn{
  opacity: 0.6;
  cursor: auto !important;
}
.requiredField{
  color: red;
  margin-left: 0.2rem;
}
.newTableWrp{
  max-width: 80% !important;
  margin: 0 auto !important;
}